import React, { useEffect, useState } from "react"
import { Text, Heading } from "@chakra-ui/react"

import ResponsiveBlock from "../components/shared/responsive-block"
import { getCBBLocks } from "../utils/lock-picking/get-locks"
import { LockType } from "../utils/lock-picking/types"
import SEO from "../components/shared/SEO"
import LockTable from "../components/shared/lock-table"
import Header from "../components/shared/header"
import { getTotalGamesFromLock } from "~utils/helpers"
import { format } from "date-fns/esm"
import { FilterType } from "~components/shared/action-bar/filters"
import ActionBar from "~components/shared/action-bar/action-bar"

const CfbPage = () => {
    const [errorOutput, setErrorOutput] = useState("")

    const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"))

    const [filters, setFilters] = useState<FilterType | undefined>(undefined)

    const [searchValue, setSearchValue] = useState("")

    const [columns, setColumns] = useState(2)

    const [locks, setLocks] = useState<LockType[]>([])

    useEffect(() => {
        try {
            if (!date) return
            getCBBLocks(
                new Date(date + "T00:00:00"),
                filters?.maxGamesToCompare
            ).then(res => {
                let filteredResults = res.filter(
                    r =>
                        getTotalGamesFromLock(r.away) >=
                            (filters?.overMatches || 0) &&
                        getTotalGamesFromLock(r.home) >=
                            (filters?.overMatches || 0)
                )

                filteredResults = !searchValue
                    ? filteredResults
                    : filteredResults.filter(
                          r =>
                              r.home.team
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase()) ||
                              r.away.team
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase())
                      )

                if (filters?.sortByAtsPlusMinus) {
                    filteredResults.sort(
                        (a, b) =>
                            Math.abs(b.atsMargin.home - b.atsMargin.away) -
                            Math.abs(a.atsMargin.home - a.atsMargin.away)
                    )
                }

                if (filters?.sortByWeightedAts) {
                    filteredResults.sort(
                        (a, b) =>
                            Math.abs(
                                b.weightedAtsMargin.home -
                                    b.weightedAtsMargin.away
                            ) -
                            Math.abs(
                                a.weightedAtsMargin.home -
                                    a.weightedAtsMargin.away
                            )
                    )
                }

                if (filters?.sortByMasterlock) {
                    filteredResults.sort(
                        (a, b) =>
                            Math.abs(b.winPercent.home - b.winPercent.away) -
                            Math.abs(a.winPercent.home - a.winPercent.away)
                    )
                }

                if (filters?.sortByAbs) {
                    filteredResults.sort((a, b) => {
                        const absA =
                            (Math.abs(a.atsMargin.home) +
                                Math.abs(a.atsMargin.away)) /
                            2
                        const absB =
                            (Math.abs(b.atsMargin.home) +
                                Math.abs(b.atsMargin.away)) /
                            2
                        return absB - absA
                    })
                }

                setLocks(filteredResults)
            })
            setErrorOutput("")
        } catch (err) {
            setErrorOutput(err as any)
        }
        return undefined
    }, [date, filters, searchValue])

    return (
        <>
            <SEO
                title="College Basketball"
                description="Picking locks and takin shots"
                imageUrl="https://www.denofgeek.com/wp-content/uploads/2019/11/adam_sandler_in_a24s_uncut_gems.jpg?fit=1200%2C687"
            />

            <Header />
            <ResponsiveBlock>
                <Heading size="md">NCAA Basketball</Heading>
                <ActionBar
                    date={date}
                    setDate={setDate}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    filters={filters}
                    setFilters={setFilters}
                    columns={columns}
                    setColumns={setColumns}
                />
                <Text color="red">{errorOutput}</Text>
                <LockTable locks={locks} />
            </ResponsiveBlock>
        </>
    )
}

export default CfbPage
